import { CircularProgress, TextField } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as ImgLogo } from "../assets/logo_vert.svg";

import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { setSettings } from "../store/settingsSlice";
import { useAuth } from "../utils/useAuth";
import useCustomNavigate from "./hooks/useCustomNavigate";
import { MuiOtpInput } from "mui-one-time-password-input";
import { styled } from "@mui/system";

const MuiOtpInputStyled = styled(MuiOtpInput)(({}) => ({
  [`&`]: {
    gap: "8px",
  },
  ".MuiOtpInput-TextField .MuiInputBase-input": {
    padding: "13px",
  },
}));

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    borderRadius: "5px",
    minWidth: "288px",
    maxWidth: "288px",
  },

  logo: {
    width: "120px",
  },

  icon: {
    marginRight: "12px",
    marginLeft: "0",
    color: "#686868",
  },

  modalDialog: {
    paddingTop: 0,
  },

  modalContent: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "16px",
    paddingTop: "4px !important",
  },

  loginButton: {
    marginBottom: "16px",
    boxShadow: "none",
  },

  modalAppBackDrop: {
    background: "#333333 url('/background-login.jpg') no-repeat center / cover",
  },

  errorMessage: {
    border: `1px solid #D50000`,
    marginTop: "10px",
    marginBottom: "15px",
    padding: "7px",
    color: "#D50000",
    fontSize: "16px",
    fontFamily: "Roboto-Regular",
  },
}));

const Login = React.memo((props) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const theme = useTheme();
  const { login, loginFromApp, submitOTP } = useAuth();
  const history = useCustomNavigate();

  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoginViaOtp, setIsLoginViaOtp] = useState(false);
  const [isTwoFA, setIsTwoFA] = useState(false);
  const [twoFaHint, setTwoFaHint] = useState("");
  const [twoFaTitle, setTwoFaTitle] = useState("");

  const [otp, setOtp] = useState("");
  const [loginText, setLoginText] = useState("");
  const [passwordText, setPasswordText] = useState("");

  const asyncCall = async () => {
    const params = new URLSearchParams(location.search);

    if (params.get("token")) {
      localStorage.setItem("refreshToken", params.get("token"));
      localStorage.setItem("tokenId", params.get("tokenId"));

      try {
        await loginFromApp();
        history("/boards");
      } catch {
        history("/login");
      }
    }
  };

  const fromLinkToView = async () => {
    const params = new URLSearchParams(location.search);

    if (params.get("token")) {
      const decoded = jwt_decode(params.get("token"));

      if (decoded?.token && decoded?.dashboardId) {
        localStorage.setItem("refreshToken", decoded.token);
        localStorage.setItem("linkToViewBoardId", decoded?.dashboardId);

        try {
          await loginFromApp();
          dispatch(
            setSettings({
              isViewMode: true,
              isFullScreen: true,
              isDrawerOpen: false,
            }),
          );
          history(`/boards/${decoded?.dashboardId}`, {
            mode: 0,
          });
        } catch {
          history("/login");
        }
      }
    }
  };

  useEffect(() => {
    if (props.fromApp) {
      asyncCall();
    }

    if (props.view) {
      fromLinkToView();
    }
  }, [props.fromApp, props.view]);

  const handleClickLogin = async (e) => {
    setLoading(true);
    setLoginError(false);
    try {
      const loginData = await login({
        userLogin: loginText,
        userPassword: passwordText,
      });

      if (loginData?.twoFA) {
        setTwoFaHint(`OTP-code has been sent by ${loginData?.twoFA.type}`);
        setTwoFaTitle(`${loginData?.twoFA.info}-XXXXXX`);
        setIsTwoFA(true);
      } else {
        const fromLinkToView = localStorage.getItem("linkToViewBoardId");

        if (fromLinkToView && searchParams.get("mode")) {
          history(`/boards/${fromLinkToView}`);
        } else {
          history("/boards");
        }
      }
    } catch (err) {
      toast.error(
        "Login or password is not correct. Try again or reset password.",
        {
          duration: 3000,
        },
      );

      setLoginError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClickLoginWithOtp = async (e) => {
    setIsLoginViaOtp(true);
    setLoginError(false);
    try {
      await submitOTP(otp);
      const fromLinkToView = localStorage.getItem("linkToViewBoardId");

      if (fromLinkToView && searchParams.get("mode")) {
        history(`/boards/${fromLinkToView}`);
      } else {
        history("/boards");
      }
    } catch (err) {
      toast.error(
        err.message,
        {
          duration: 3000,
        },
      );

      setLoginError(true);
    } finally {
      setIsLoginViaOtp(false);
    }
  };

  return (
    <>
      <Toaster />
      <Dialog
        open={true}
        className={classes.modalDialog}
        BackdropProps={{
          classes: {
            root: classes.modalAppBackDrop,
          },
        }}
        PaperProps={{
          classes: { root: classes.modalPaper },
          style: { pointerEvents: "auto" },
        }}
        hideBackdrop={false}
        disableEnforceFocus={true}
        style={{ pointerEvents: "none" }}
      >
        <DialogContent className={classes.modalContent}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{ height: "100%" }}
          >
            {isTwoFA && <>
              <Grid container direction="column">
                <Grid item style={{ textAlign: "center" }}>
                  <ImgLogo className={classes.logo} />
                </Grid>

                <Grid
                  container
                  alignItems="flex-end"
                  style={{ paddingBottom: "15px", paddingTop: "10px" }}
                >
                  <MuiOtpInputStyled
                    autoFocus={true}
                    title={twoFaTitle}
                    length={6}
                    value={otp}
                    onChange={(e) => setOtp(e)}
                  />

                </Grid>
                <Grid item style={{ paddingBottom: "15px" }}>
                  <Typography variant="subtitle1" style={{ opacity: ".6" }}>{twoFaHint}</Typography>
                </Grid>
                <Grid item style={{ textAlign: "center" }}>
                  <Button
                    data-test="actionResendLoginWithOtp"
                    disabled={loading}
                    disableElevation={true}
                    onClick={handleClickLogin}
                    className={classes.loginButton}
                    variant="outlined"
                    color="primary"
                    fullWidth
                  >
                    {loading ? (
                      <CircularProgress size={22} />
                    ) : (
                      <Typography variant="subtitle2">Resend</Typography>
                    )}
                  </Button>
                </Grid>
                <Grid item style={{ textAlign: "center" }}>
                  <Button
                    data-test="actionLoginWithOtp"
                    disabled={isLoginViaOtp || otp.length < 6}
                    disableElevation={true}
                    onClick={handleClickLoginWithOtp}
                    className={classes.loginButton}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {isLoginViaOtp ? (
                      <CircularProgress size={22} />
                    ) : (
                      <Typography variant="subtitle2">Continue</Typography>
                    )}
                  </Button>
                </Grid>

              </Grid>
            </>}
            {!isTwoFA && <>
              {!props.fromApp && !props.view && (
                <>
                  <Grid container direction="column">
                    <Grid item style={{ textAlign: "center" }}>
                      <ImgLogo className={classes.logo} />
                    </Grid>

                    <Grid
                      container
                      alignItems="flex-end"
                      style={{ paddingBottom: "20px", paddingTop: "10px" }}
                    >
                      <Grid item>
                        <PersonIcon className={classes.icon} />
                      </Grid>
                      <Grid xs item>
                        <TextField
                          variant="standard"
                          fullWidth
                          inputProps={{
                            "data-test": "login",
                          }}
                          name="login"
                          label="Login"
                          value={loginText}
                          onChange={(e) => {
                            setLoginText(e.target.value);
                          }}
                          onFocus={(e) => {
                            setLoginError(false);
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="flex-end"
                      style={{ paddingBottom: "30px" }}
                    >
                      <Grid item>
                        <LockIcon className={classes.icon} />
                      </Grid>
                      <Grid xs item>
                        <TextField
                          inputProps={{
                            "data-test": "password",
                          }}
                          variant="standard"
                          fullWidth
                          type="password"
                          name="password"
                          label="Password"
                          value={passwordText}
                          onChange={(e) => {
                            setPasswordText(e.target.value);
                          }}
                          onFocus={(e) => {
                            setLoginError(false);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Button
                      data-test="actionLogin"
                      disabled={loading}
                      disableElevation={true}
                      onClick={handleClickLogin}
                      className={classes.loginButton}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {loading ? (
                        <CircularProgress size={22} />
                      ) : (
                        <Typography variant="subtitle2">Login</Typography>
                      )}
                    </Button>
                  </Grid>
                </>
              )}

              {(props.fromApp || props.view) && (
                <Grid container direction="column">
                  <Grid item style={{ textAlign: "center" }}>
                    <ImgLogo className={classes.logo} />
                  </Grid>

                  <Grid
                    container
                    justifyContent="center"
                    style={{ paddingBottom: "20px", paddingTop: "10px" }}
                  >
                    <Grid item>Entering to app...</Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ paddingBottom: "20px", paddingTop: "10px" }}
                    justifyContent="center"
                  >
                    <Grid item justifyContent="center">
                      <CircularProgress size={40} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default Login;
